(function ($) {

    if ($('main.cadastro').length) {
        var _arrayElements = [];
        _arrayElements.push(document.getElementById('txtCpf'));
        _arrayElements.push(document.getElementById('txtTelefone'));
        _arrayElements.push(document.getElementById('txtCep'));

        applyMask(_arrayElements);
    }

    $("#btn-salvar-user").css("cursor", "auto");
    $(document).on('click', '[name="aceite"]', function (e) {
        let cont = $("[name='aceite']:checked").length;

        $("#btn-salvar-user").prop("disabled", cont ? false : true);
        $("#btn-salvar-user").css("cursor", "pointer");
    });

})(jQuery);
