var isAdvancedUpload = function () {
    var div = document.createElement('div');

    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
};

(function ($) {
    var _form = $('.box');
    var _input = $('#files');
    var _label    = _form.find('label');
    var showFiles = function(files) {
        _label.text(files.length > 1 ? (_input.attr('data-multiple-caption') || '').replace( '{count}', files.length ) : files[ 0 ].name);
    };

    function updateDadosManifesto(listFiles) {
        _dadosManifesto.anexo = listFiles;
    }

    if (isAdvancedUpload) {
        _form.addClass('has-advanced-upload');
    }

    var droppedFiles = false;

    _form.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
            e.preventDefault();
            e.stopPropagation();
        }).on('dragover dragenter', function () {
            _form.addClass('is-dragover');
        }).on('dragleave dragend drop', function () {
            _form.removeClass('is-dragover');
        }).on('drop', function (e) {
            droppedFiles = e.originalEvent.dataTransfer.files; // the files that were dropped
            showFiles( droppedFiles );

            updateDadosManifesto(droppedFiles);
        }).on('submit', function (e) {

            if (_form.hasClass('is-uploading'))
                return false;

            _form.addClass('is-uploading');

            if (isAdvancedUpload) {
                // ajax for modern browsers

                e.preventDefault();

                var ajaxData = new FormData(_form.get(0));

                if (droppedFiles) {
                    $.each( droppedFiles, function (i, file) {
                        ajaxData.append(_input.attr('name'), file);
                    });
                }

                $.ajax({
                    url: _form.attr('action'),
                    type: _form.attr('method'),
                    data: ajaxData,
                    dataType: 'json',
                    cache: false,
                    contentType: false,
                    processData: false
                }).done(function (response) {
                    console.log('Success: ', response);

                    _form.addClass(response.error === false ? 'is-success' : 'is-error');
                    if (response.error) _errorMsg.text(response.msg);

                }).fail(function (data) {

                    console.log('Fail: ', data);

                }).always(function () {

                    _form.removeClass('is-uploading');

                });

            } else {
                // ajax for legacy browsers

                var iframeName  = 'uploadiframe' + new Date().getTime();
                $iframe = $('<iframe name="' + iframeName + '" style="display: none;"></iframe>');

                $('body').append($iframe);
                _form.attr('target', iframeName);

                $iframe.one('load', function() {
                    var data = JSON.parse($iframe.contents().find('body' ).text());

                    _form
                        .removeClass('is-uploading')
                        .addClass(data.success == true ? 'is-success' : 'is-error')
                        .removeAttr('target');

                    if (!data.success) $errorMsg.text(data.error);
                    _form.removeAttr('target');
                    $iframe.remove();
                });
            }
        });

    _input.on('change', function(e) { // when drag & drop is NOT supported
        droppedFiles = this.files; // the files that were selected
        showFiles( droppedFiles );

        updateDadosManifesto(droppedFiles);
    });
})(jQuery);