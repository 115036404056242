jQuery(document).ready(function ($) {

    function registraEventos() {

        var _btnToggleMenu = $('.bevicred-toggle-menu');

        _btnToggleMenu.on('click', function (event) {

            var _this = $(this);
            var _target = $('.' + _this.attr('data-target'));
            var _body = $('body');

            _target.toggleClass('active');

        });

    }

    function init() {
        registraEventos();

        // window.onscroll = function() {stickyHeader()};

        var header = $('.hidden-if-sticked');
        var sticky = header.offset();
        var _body = $('body');
        var _floatBtnBottom = $('.floatBtnBottom');

        // function stickyHeader() {
        //     if (window.pageYOffset > sticky.top + 30) {
        //         _body.addClass('menu-fixo');
        //         _body.addClass('border-shadow');
        //         _floatBtnBottom.addClass('visible');
        //     } else {
        //         _body.removeClass('menu-fixo');
        //         _body.removeClass('border-shadow');
        //         _floatBtnBottom.removeClass('visible');
        //     }
        // }
    }

    init();
    console.log('Top Container Rodando');
});