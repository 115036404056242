jQuery(document).ready(function ($) {
    console.log('Render EJS - Rodando');

});

function ejsRenderElement(idSource, classTarget, json) {

    var _template = $('#' + idSource).html();

    var _html = ejs.compile(_template)(json);

    $('.' + classTarget).html(_html);
};
