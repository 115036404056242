var _dadosManifesto = {};
var _listEnvolvidos = [];


function applyMask(arrayElements) {

    var maskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    };
    var options = {
        onKeyPress: function(val, e, field, options) {
            field.mask(maskBehavior.apply({}, arguments), options);
        }
    };

    $.each(arrayElements, function (index, value) {

        var _this = $(value);

        switch (_this.attr('mask-type')) {

            case 'date':
                _this.mask('11/11/1111');
                break;

            case 'time':
                _this.mask('00:00:00');
                break;

            case 'date_time':
                _this.mask('00/00/0000 00:00:00');
                break;

            case 'cep':
                _this.mask('00000-000');
                break;

            case 'phone':
                _this.mask(maskBehavior, options);
                break;

            case 'phone_with_ddd':
                _this.mask(maskBehavior, options);
                break;

            case 'phone_us':
                _this.mask('(000) 000-0000');
                break;

            case 'cpf':
                _this.mask('000.000.000-00', {reverse: true});
                break;

            case 'money':
                _this.mask('000.000.000.000.000,00', {reverse: true});
                break;

            default: console.log("Tipo de mascara não identificado.");
        }

        console.log('Elemento mascarado!');
    });

}

function obterEstados() {

    $.ajax({
        url: window.suavozSettings.API_URL + '/estado'
    }).done(function (response) {
        var arraySelect2 = [];

        $.each(response, function (index, element) {

            arraySelect2.push({
                id: element.sigla,
                text: element.nome
            });
        });

        $('#slcEstado').select2({
            data: arraySelect2,
            language: 'pt-br'
        });

        $('#txtMunicipio').select2();

        $('#slcEstado').on('select2:select', function (e) {
            var data = e.params.data;

            $.ajax({
                url: window.suavozSettings.API_URL + '/estado/municipio/' + data.id,
                beforeSend: function (e) {
                    console.log('Destroy select');
                    $('#txtMunicipio').select2('destroy');
                    $('#txtMunicipio option').remove();
                }

            }).done(function (response) {
                var arraySelect2 = [];

                $.each(response, function (index, element) {

                    arraySelect2.push({
                        id: element.nome,
                        text: element.nome
                    });
                });

                $('#txtMunicipio').select2({
                    data: arraySelect2,
                    language: 'pt-br'
                });

            });
        });
    });
}

(function ($) {

    if ($('.cardEnvolvidos').length) {

        ejsRenderElement('cardEnvolvidos', 'cardEnvolvidos', {
            'listEnvolvidos': _listEnvolvidos
        });

    }

    var _btnAddEnvolvido = $('.btn-addEnvolvido');

    _btnAddEnvolvido.on('click', function () {

        $('.envolvido-alert').addClass('d-none');

        var _txtNomeEnvolvido = $('#txtNomeEnvolvido');
        var _txtFuncaoEnvolvido = $('#txtFuncaoEnvolvido');

        if (_txtNomeEnvolvido.val().trim() === '' && _txtFuncaoEnvolvido.val().trim() === '') {
            return false;
        }

        if (_listEnvolvidos.length === 5) {
            $.alert({
                title: 'Ops!',
                content: 'Adicione até 5 envolvidos.',
                buttons: {
                    Ok: {
                        text: 'Ok',
                        btnClass: 'btn-blue',
                        keys: ['enter']
                    }
                },
                draggable: true
            });
            return;
        }

        _listEnvolvidos.push({
            'nome': _txtNomeEnvolvido.val().trim() !== '' ? _txtNomeEnvolvido.val().trim() : '---',
            'funcao': _txtFuncaoEnvolvido.val().trim() !== '' ? _txtFuncaoEnvolvido.val().trim() : '---',
            'key': Math.floor(Math.random() * 1000000) + 1
        });

        ejsRenderElement('cardEnvolvidos', 'cardEnvolvidos', {
            'listEnvolvidos': _listEnvolvidos
        });

        _txtNomeEnvolvido.val('');
        _txtFuncaoEnvolvido.val('');

    });

    $(document).on('click', '[data-action="remover"]', function (e) {

        var _this = $(this);
        var _key = parseInt(_this.closest('tr').attr('data-key'));

        for(var i = _listEnvolvidos.length - 1; i >= 0; i--) {

            if (parseInt(_listEnvolvidos[i].key) === _key) {
                _listEnvolvidos.splice(i, 1);
            }
        }

        ejsRenderElement('cardEnvolvidos', 'cardEnvolvidos', {
            'listEnvolvidos': _listEnvolvidos
        });
    });

    $(document).on('click', '#btn-avancar-informacoes', function (e) {

        var _txtDescricao = $('#txtDescricao');

        _txtDescricao.removeClass('border-danger');

        if (_txtDescricao.val().trim() === '') {

            _txtDescricao.addClass('border-danger');
            $('html, body').animate({scrollTop: (_txtDescricao.offset().top - 100)}, 500);

        } else if ($('#txtNomeEnvolvido').val().trim() !== '' || $('#txtFuncaoEnvolvido').val().trim() !== '') {
            $('.envolvido-alert').removeClass('d-none');
        } else {

            var _steps = $('.step-container .stepper-horizontal .step');
            var _formDadosManifesto = $('.form-dados-manifesto');
            var _recapitulacaoDados = $('.recapitulacao-dados');

            _formDadosManifesto.addClass('d-none');
            _recapitulacaoDados.removeClass('d-none');

            _steps.each(function (index) {

                if ($(this).hasClass('step-2')) {
                    $(this).addClass('active done').removeClass('editable');
                }

                if ($(this).hasClass('step-3')) {
                    $(this).addClass('active editable');
                }

            });

            // Pegar dados do Formulário para Preencher o resumo
            _dadosManifesto.descricao = _txtDescricao.val();
            _dadosManifesto.listEnvolvidos = _listEnvolvidos;

            _dadosManifesto.localFatos = {};
            _dadosManifesto.localFatos.estado = $('#slcEstado option:selected').text();
            _dadosManifesto.localFatos.municipio = $('#txtMunicipio').val();
            _dadosManifesto.localFatos.nome = $('#txtNomeCompleto').val();
            _dadosManifesto.localFatos.email = $('#txtEmail').val();
            _dadosManifesto.localFatos.telefone = $('#txtTelefone').val();

            ejsRenderElement('resumo-manifestacao', 'resumo-manifestacao', {
                'dadosManifesto': _dadosManifesto
            });
        }

    });

    $(document).on('click', '#btn-voltar-recapitulacao', function (e) {

        var _steps = $('.step-container .stepper-horizontal .step');

        _steps.each(function (index) {

            if ($(this).hasClass('step-3')) {
                $(this).removeClass('editable active');
            }

            if ($(this).hasClass('step-2')) {
                $(this).addClass('editable').removeClass('done');
            }

        });

        var _formDadosManifesto = $('.form-dados-manifesto');
        var _recapitulacaoDados = $('.recapitulacao-dados');

        _recapitulacaoDados.addClass('d-none');
        _formDadosManifesto.removeClass('d-none');

    });

    $(document).on('click', '#btn-avancar-recapitulacao', function (e) {

        e.preventDefault();

        var _input = $('#files');
        var ajaxData = new FormData();

        ajaxData.append('tipoManifesto', _dadosManifesto.tipoManifesto);
        ajaxData.append('txtNomeCompleto', $('#txtNomeCompleto').val());
        ajaxData.append('txtEmail', $('#txtEmail').val());
        ajaxData.append('txtTelefone', $('#txtTelefone').val());
        ajaxData.append('txtDescricao', $('#txtDescricao').val());
        ajaxData.append('slcEstado', $('#slcEstado').val());
        ajaxData.append('txtMunicipio', $('#txtMunicipio').val());
        ajaxData.append('aceite', $('#aceite').val());

        $.each(_listEnvolvidos, function (i, item) {
            ajaxData.append('envolvidoNome' + i, item.nome);
            ajaxData.append('envolvidoFuncao' + i, item.funcao);
        });

        $.each( _input[0].files, function (i, file) {
            ajaxData.append(_input.attr('name'), file);
        });

        var settings = {
            'url': '/createTicket',
            'type': 'POST',
            'data': ajaxData,
            'dataType': 'json',
            'cache': false,
            'contentType': false,
            'processData': false,
            beforeSend: function () {
                jDialog.openLoading('Carregando...');
            },
            complete: function () {

                var _recapitulacaoDados = $('.recapitulacao-dados');
                var _conclusaoTicket = $('.conclusao-ticket');

                _recapitulacaoDados.addClass('d-none');
                _conclusaoTicket.removeClass('d-none');

                var _steps = $('.step-container .stepper-horizontal .step');

                _steps.each(function (index) {

                    if ($(this).hasClass('step-3')) {
                        $(this).addClass('active done').removeClass('editable');
                    }

                    if ($(this).hasClass('step-4')) {
                        $(this).addClass('active editable');
                    }

                });

                jDialog.closeLoading();
            }
        };

        $.ajax(settings).done(function (response) {

            console.log('success: ', response);
            ejsRenderElement('conclusao-manifestacao', 'conclusao-manifestacao', {
                error: false,
                protocolo: response.data.id
            });
        }).fail(function (data) {
            console.error('Fail');
            ejsRenderElement('conclusao-manifestacao', 'conclusao-manifestacao', {
                error: true,
                protocolo: '',
            });
        });

    });

    $(document).on('click', '#btnSair', function (e) {

        let form = this.closest('form');
        form.submit();
    });

    const chkAceite = document.getElementById('aceite');

    if(chkAceite)
    {
        chkAceite.addEventListener('change', function (event) {
            const btnAvancarInformacoes = document.getElementById('btn-avancar-informacoes');
            if (document.getElementById('aceite').checked) {
                btnAvancarInformacoes.removeAttribute('disabled');
                btnAvancarInformacoes.style.cursor = 'pointer';
            } else {
                btnAvancarInformacoes.setAttribute('disabled', '1');
                btnAvancarInformacoes.style.cursor = 'auto';
            }
        });
    }

})(jQuery);
