(function ($) {
    var requesterId = 0;

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validadeNumber(number) {
        var re = /^\d*$/;
        return re.test(number);
    }


    $(document).on('click', '#btn-manifesto-consultar', function () {
        console.log('consultando');
        var _input = $('#keySearchManifesto');

        if (_input.val().trim() !== '') {
            var data = {};
            var settings = {
                url: '/manifesto/list',
                beforeSend: function () {
                    jDialog.openLoading('Carregando...');
                },
                complete: function () {
                    jDialog.closeLoading();
                }
            };

            var inputValue = $('#keySearchManifesto').val().trim();

            if (validateEmail(inputValue)) {
                data.email = inputValue;
            } else if(validadeNumber(inputValue)) {
                data.idTicket = inputValue;
            } else {
                alert('Valor inválido.');
                return false;
            }

            settings.data = data;

            $.ajax(settings).done(function (response) {
                console.log('Response: ', response);

                var tickets = [];

                if (!response.dados.error) {
                    if (typeof response.dados.data.results === 'undefined') {
                        tickets = [response.dados.data.ticket]
                    } else {
                        tickets = response.dados.data.results;
                    }

                    requesterId = parseInt(tickets.length > 0 ? tickets[0].requester_id : 0);

                }

                ejsRenderElement('templateListTickets', 'templateManifestos', {
                    tickets: tickets
                });
            }).fail(function (data) {
                console.log('Fail: ', data);
            });
        }

    });

    $(document).on('click', '[data-ticket-id]', function () {

        var _this = $(this);
        var _idTicket = parseInt(_this.attr('data-ticket-id'));

        var settings = {
            url: '/manifesto/comment/' + _idTicket,
            beforeSend: function () {
                jDialog.openLoading('Carregando...');
            },
            complete: function () {
                jDialog.closeLoading();
            }
        };

        $.ajax(settings).done(function (response) {
            console.log('Response: ', response);

            var comments = [];

            if (typeof response.dados.data.comments !== 'undefined') {
                comments = response.dados.data.comments;
            }

            ejsRenderElement('templateListCommentFromTicket', 'templateManifestos', {
                ticket: _idTicket,
                comments: comments,
                requesterId: requesterId
            });

        }).fail(function (data) {
            console.log('Fail: ', data);
        });

    });

    $(document).on('keyup', '#keySearchManifesto', function (e) {

        if(e.which === 13) {

            $('#btn-manifesto-consultar').click();
        }
    });

})(jQuery);
