jQuery(document).ready(function ($) {

    function init() {

        // Tooltips
        var cardElogio      = $('.item.elogio');
        var cardSugestao    = $('.item.sugestao');
        var cardReclamacao  = $('.item.reclamacao');
        var cardDenuncia    = $('.item.denuncia');

        cardElogio.tooltip();
        cardSugestao.tooltip();
        cardReclamacao.tooltip();
        cardDenuncia.tooltip();


        // Cards Events
        var _item = $('.items .item');
        var _steps = $('.step-container .stepper-horizontal .step');
        var _cardsManifesto = $('.cards-manifesto');
        var _formDadosManifesto = $('.form-dados-manifesto');

        _item.on('click', function () {

            var _this = $(this);
            var _tipoManifesto = _this.attr('tipo-manifesto')

            _dadosManifesto.tipoManifesto = _tipoManifesto;

            // Adicionar classe "active, done e editable" nas respectivas steps

            _cardsManifesto.hide();
            _formDadosManifesto.find('.bloco.descricao .dados').addClass(_tipoManifesto);
            _formDadosManifesto.removeClass('d-none');

            _steps.each(function (index) {

                if ($(this).hasClass('step-1')) {
                    $(this).addClass('active done');
                }

                if ($(this).hasClass('step-2')) {
                    $(this).addClass('active editable');
                }

            });
        });
    }

    init();
    console.log('Tooltips Rodando');
});